module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Webolo | Web application development","short_name":"Webolo","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"fullscreen","icon":"./src/visuals/images/icon-v6.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7a22b89e260cdd224b40a4b22802962d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/inquiry/","/technologies/","/security/","/references/","/speed/","/","/architecture-and-design/"],"appendScript":"/Users/mac/www/projekty/pwa/webolo/eng/src/behavior/worker.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WZEG8SH937"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"exclude":[]}},
    }]
